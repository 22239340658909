// themeRoutes.js
const pages = {
	index: '/',
	not_found: '/not-found',
	games: '/games',
	history: '/history',
	hold_balance: '/hold-balance',
	cashier: "/cashier",
	detail: "/detail",
	download: "/download",
	favorite_game: "/favorite-games",
	forget_password: "/forgot-password",
	leader_board: "/leaderboard",
	login: "/login",
	my_bonus: "/my-bonus",
	no_support: "/no-support",
	payment_methods: "/payment-methods",
	play_game: "/play-game",
	promotion: "/promotion",
	recently: "/recently",
	refer_friend: "/refer-friend",
	referral: "/referral",
	register: "/register",
	reset_password: "/reset-password",
	download_android: "/download/android",
	download_ios: "/download/ios",
	history_game: "/history/game",
	history_transaction: "/history/transaction",
	payment_methods_add_bank: "/payment-methods/add-bank",
	payment_methods_add_crypto: "/payment-methods/add-crypto-wallet",
	player_bank: "/player/banks",
	player_bet_history: "/player/bet-history",
	player_deposit: "/player/deposits",
	player_messages: "/player/messages",
	player_profile: "/player/profile",
	player_wallets: "/player/wallets",
	player_withdrawal: "/player/withdrawals",
	promotion_bonus: "/promotion/bonus",
	provider_games: "/providers/games",
	player_deposit_apply: "/player/deposits/apply",
	player_deposits_bank_transfer: "/player/deposits/bank-transfer",
	player_deposits_confirm: "/player/deposits/confirm",
	player_deposits_crypto_payment: "/player/deposits/crypto-payment",
	player_deposits_online_payment: "/player/deposits/online-payment",
	player_deposits_quick_payment: "/player/deposits/quick-payment",
	player_withdrawal_apply: "/player/withdrawals/apply",
	player_withdrawals_bank_withdrawal: "/player/withdrawals/bank-withdrawal",
	player_withdrawals_crypto_withdrawal: "/player/withdrawals/crypto-withdrawal",
	payment_methods_edit_bank_id: "/payment-methods/edit/bank/:id?",
	payment_methods_edit_crypto_id: "/payment-methods/edit/crypto/:id?",
	player_deposits_detail_id: "/player/deposits/detail/:id?",
	player_withdrawal_detail_id: "/player/withdrawals/detail/:id?",
	providers_game_id: "/providers/games/:id",
	information_id: "/Information/:id?",
	payment_methods_bank_id: "/payment-methods/:bankId?",
	promotion_id: "/promotion/:id",
	provider_id: "/providers/:id?",
	article_content: "/articles/:title?/:slug?",
	articles: "/articles",
	referral_link: "/:type/:id?"
}
export default {
	default: [
		pages.index,
		pages.articles,
		pages.not_found,
		pages.games,
		pages.history,
		pages.hold_balance,
		pages.cashier,
		pages.detail,
		pages.download,
		pages.favorite_game,
		pages.forget_password,
		pages.leader_board,
		pages.login,
		pages.my_bonus,
		pages.play_game,
		pages.promotion,
		pages.refer_friend,
		pages.referral,
		pages.register,
		pages.reset_password,
		pages.download_android,
		pages.download_ios,
		pages.history_game,
		pages.history_transaction,
		pages.player_deposit,
		pages.player_messages,
		pages.player_profile,
		pages.player_wallets,
		pages.player_withdrawal,
		pages.promotion_bonus,
		pages.provider_games,
		pages.player_deposit_apply,
		pages.player_deposits_confirm,
		pages.player_withdrawal_apply,
		pages.payment_methods_edit_bank_id,
		pages.payment_methods_edit_crypto_id,
		pages.player_withdrawal_detail_id,
		pages.providers_game_id,
		pages.information_id,
		pages.promotion_id,
		pages.provider_id,
		pages.article_content,
		pages.referral_link
	],
	'theme-1': [
		pages.index,
		pages.articles,
		pages.not_found,
		pages.games,
		pages.history,
		pages.hold_balance,
		pages.cashier,
		pages.detail,
		pages.download,
		pages.favorite_game,
		pages.forget_password,
		pages.leader_board,
		pages.login,
		pages.my_bonus,
		pages.play_game,
		pages.promotion,
		pages.refer_friend,
		pages.referral,
		pages.register,
		pages.reset_password,
		pages.download_android,
		pages.download_ios,
		pages.history_game,
		pages.history_transaction,
		pages.player_deposit,
		pages.player_messages,
		pages.player_profile,
		pages.player_wallets,
		pages.player_withdrawal,
		pages.promotion_bonus,
		pages.provider_games,
		pages.player_deposit_apply,
		pages.player_deposits_confirm,
		pages.player_withdrawal_apply,
		pages.payment_methods_edit_bank_id,
		pages.payment_methods_edit_crypto_id,
		pages.player_withdrawal_detail_id,
		pages.providers_game_id,
		pages.information_id,
		pages.promotion_id,
		pages.provider_id,
		pages.article_content,
		pages.referral_link
	],
	'theme-2': [
		pages.index,
		pages.articles,
		pages.download,
		pages.not_found,
		pages.games,
		pages.history,
		pages.hold_balance,
		pages.detail,
		pages.cashier,
		pages.forget_password,
		pages.login,
		pages.my_bonus,
		pages.no_support,
		pages.payment_methods,
		pages.play_game,
		pages.promotion,
		pages.refer_friend,
		pages.register,
		pages.download_android,
		pages.download_ios,
		pages.history_game,
		pages.history_transaction,
		pages.payment_methods_add_bank,
		pages.payment_methods_add_crypto,
		pages.player_messages,
		pages.player_profile,
		pages.player_deposit_apply,
		pages.player_withdrawal_apply,
		pages.payment_methods_edit_bank_id,
		pages.payment_methods_edit_crypto_id,
		pages.payment_methods_bank_id,
		pages.promotion_id,
		pages.article_content,
		pages.referral_link,
		pages.reset_password
	],
	'theme-3': [
		pages.index,
		pages.articles,
		pages.not_found,
		pages.history,
		pages.hold_balance,
		pages.cashier,
		pages.detail,
		pages.download,
		pages.forget_password,
		pages.login,
		pages.my_bonus,
		pages.no_support,
		pages.payment_methods,
		pages.play_game,
		pages.promotion,
		pages.refer_friend,
		pages.register,
		pages.reset_password,
		pages.download_android,
		pages.download_ios,
		pages.history_game,
		pages.history_transaction,
		pages.payment_methods_add_bank,
		pages.payment_methods_add_crypto,
		pages.player_messages,
		pages.player_profile,
		pages.promotion_bonus,
		pages.player_deposit_apply,
		pages.player_withdrawal_apply,
		pages.promotion_id,
		pages.article_content,
		pages.referral_link
	],
	'theme-4': [
		pages.index,
		pages.articles,
		pages.not_found,
		pages.games,
		pages.download,
		pages.forget_password,
		pages.leader_board,
		pages.login,
		pages.no_support,
		pages.play_game,
		pages.promotion,
		pages.recently,
		pages.refer_friend,
		pages.referral,
		pages.register,
		pages.reset_password,
		pages.download_android,
		pages.download_ios,
		pages.history_game,
		pages.promotion_bonus,
		pages.player_deposit_apply,
		pages.player_deposits_bank_transfer,
		pages.player_deposits_confirm,
		pages.player_deposits_crypto_payment,
		pages.player_deposits_online_payment,
		pages.player_deposits_quick_payment,
		pages.player_withdrawal_apply,
		pages.player_withdrawals_bank_withdrawal,
		pages.player_withdrawals_crypto_withdrawal,
		pages.promotion_id,
		pages.article_content,
		pages.referral_link
	],
};
